<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="configurePage" :fields="fields" :search-model="searchModel"
             :show-selection="true"   :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.utype" clearable placeholder="类型">
            <el-option label="开发商" value="0"></el-option>
            <el-option label="经销商" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="developersId">
          <el-select v-model="searchModel.developersId" filterable clearable placeholder="开发商名称">
            <el-option
                v-for="item in developer_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="distributorId">
          <el-select v-model="searchModel.distributorId" filterable clearable placeholder="经销商名称">
            <el-option
                v-for="item in distributor_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.state" clearable placeholder="有效状态">
            <el-option label="有效" value="0"></el-option>
            <el-option label="失效" value="1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="exportData"  size="mini" type="primary">批量导出</el-button>
        <el-button @click="handleAdd"  size="mini" type="primary">新建</el-button>
      </template>
    </page-table>
    <el-dialog
        title="编辑配置"
        :visible.sync="page_dialog_visible"
        append-to-body
        :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="pageForm" :rules="pageRules" label-width="140px" ref="pageForm"
               v-loading="page_form_loading">

        <el-form-item label="开发商">
          <el-select v-model="pageForm.developersId" filterable clearable placeholder="开发商">
            <el-option
                v-for="item in developer_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="经销商" >
          <el-select v-model="pageForm.distributorId" filterable clearable placeholder="经销商">
            <el-option
                v-for="item in distributor_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="屋顶类型" prop="roofType" v-if="pageForm.distributorId!==''&&pageForm.distributorId!=null">
          <el-select v-model="pageForm.roofType" placeholder="屋顶类型">
            <el-option v-for="type in roof_type_options" :key="type.type" :label="type.name"
                       :value="type.type"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="租金类型" prop="type">
          <el-select v-model="pageForm.type" placeholder="租金类型" @change="typeChange">
            <el-option v-for="type in type_options" :key="type.value" :label="type.label"
                       :value="type.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效期" prop="_date">
          <el-date-picker
              @change="dateChange"
              v-model="pageForm._date"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="期数" prop="periods">
          <el-select v-model="pageForm.periods" placeholder="期数">
            <el-option v-for="periods in periods_options" :key="periods.value" :label="periods.label"
                       :value="periods.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租金价格" prop="rent_yen" v-if="pageForm.type == 0" :rules='[
          {required: true, message: "请输入租金价格", trigger: "blur"},
        ]'>
          <el-input v-model.number="pageForm.rent_yen" placeholder="租金价格"
                    @change="(val) => yenChange(val, 'rent')"></el-input>
        </el-form-item>
        <template v-else>
          <el-form-item :label="'租金价格前' + pageForm.type1 + '年'" prop="rent1_yen" :rules='[
          {required: true, message: "请输入租金价格", trigger: "blur"},
        ]'>
            <el-input v-model="pageForm.rent1_yen" :placeholder="'租金价格前' + pageForm.type1 + '年'"
                      @change="(val) => yenChange(val, 'rent1')"></el-input>
          </el-form-item>
          <el-form-item :label="'租金价格后' + pageForm.type2 + '年'" prop="rent2_yen" :rules='[
          {required: true, message: "请输入租金价格", trigger: "blur"},
        ]'>
            <el-input v-model="pageForm.rent2_yen" :placeholder="'租金价格后' + pageForm.type2 + '年'"
                      @change="(val) => yenChange(val, 'rent2')"></el-input>
          </el-form-item>
        </template>
        <div class="dialog-footer">
          <el-button @click="page_dialog_visible = false">取 消</el-button>
          <el-button @click="submit('pageForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  configurePage,
  configureCreate,
  configureUpdate,
  configureChangeState, distributorAll, roofTypeAll, developerAll, configureExport,
} from "@/api/common"
import downloadBlob from "@/function/downloadBlob";

export default {
  name: "DeveloperConfigures",
  data() {
    return {
      searchModel: {
        developersId: '',
        state: '',
        utype: ''
      },
      configurePage: configurePage,
      fields: [
        {label: '序号', key: 'id', width: 180,},
        {label: '所在省', width: 100,
          render: ({developers}, h) => {
            return h('div', developers ? developers.province : '')
          }
        },
        {label: '开发商ID', key: 'developersId', width: 200,},
        {
          label: '开发商名称',
          width: 210,
          render: ({developers}, h) => {
            return h('div', developers ? developers.name : '')
          }
        },
        // {label: '经销商ID', key: 'distributorId', width: 200},
        {
          label: '经销商',
          width: 210,
          render: ({distributor}, h) => {
            return h('div', distributor ? distributor.name : '')
          }
        },
        {
          label: '租金价格(￥)',
          width: 160,
          render: (item, h) => {
            return h('div', item.type == 0 ? (item.rent / 100).toFixed(2) : (item.rent1 / 100).toFixed(2) + ',' + (item.rent2 / 100).toFixed(2))
          }
        },
        {
          label: '租金类型',
          width: 100,
          render: (item, h) => {
            return h("span", ['通用型', '10+15型', '5+20型'][item.type])
          }
        },
        {
          label: '有效期',
          width: 250,
          render: (item, h) => {
            return h("span", item.startDate + '--' + item.endDate)
          }
        },
        {
          label: '有效状态',
          key: 'secretKey',
          width: 90,
          render: ({state},h) => {

            if (!state) {
              return h('div',"有效")
            } else {
              return h('div',"失效")
            }
          }
        },
        {label: '操作时间', key: 'uTime', width: 180, align: 'center'},
      ],
      actions: [
        {
          action: 'changeState',
          label: '生效',
          type: 'primary',
          showAction(item) {
            return item.state
          }
        },
        {
          action: 'changeState',
          label: '失效',
          type: 'primary',
          showAction(item) {
            return !item.state
          }
        },
        {
          action: 'info',
          label: '查看详情',
          type: 'primary',
        },
        {
          action: 'edit',
          label: '编辑',
          type: 'primary',
        },


      ],
      //
      page_form_loading: false,
      pageForm: {
        developersId: '',
        distributorId:'',
        type: '',
        type1: '',
        type2: '',
        startDate: '',
        endDate: '',
        _date: [],
        periods: '',
        rent: '',
        rent_yen: '',
        rent1: '',
        rent1_yen: '',
        rent2: '',
        rent2_yen: '',
        utype: 0,
      },
      pageRules: {
        developersId: [
          {required: true, message: "请选择开发商", trigger: "blur"},
        ],
        type: [
          {required: true, message: "请选择租金类型", trigger: "blur"},
        ],
        _date: [
          {required: true, message: "请选择有效期", trigger: "blur"},
        ],
        periods: [
          {required: true, message: "请选择期数", trigger: "blur"},
        ],
        roofType: [
          {required: true, message: "请选择屋顶类型", trigger: "blur"},
        ],
      },
      developer_options: [],
      distributor_options: [],
      roof_type_options: [],
      type_options: [
        {
          label: '通用型',
          value: 0,
          type1: '',
          type2: '',
        },
        {
          label: '10+15型',
          value: 1,
          type1: 10,
          type2: 15,
        },
        {
          label: '5+20型',
          value: 2,
          type1: 5,
          type2: 20,
        },
      ],
      periods_options: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '6',
          value: 6,
        },
        {
          label: '12',
          value: 12,
        },
      ],
      page_dialog_visible: false,
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {
    this.getDeveloperOptions()
    this.getDistributorOptions()
    this.getRoofTypeOptions()
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    getDeveloperOptions() {
      let that = this
      developerAll().then(res => {
        that.developer_options = res
      })
    },
    getDistributorOptions() {
      let that = this
      distributorAll().then(res => {
        that.distributor_options = res
      })
    },
    getRoofTypeOptions() {
      let that = this
      roofTypeAll().then(res => {
        that.roof_type_options = res
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      this.pageForm = {
        developersId: '',
        distributorId: '',
        type: '',
        type1: '',
        type2: '',
        startDate: '',
        endDate: '',
        _date: [],
        periods: '',
        rent: '',
        rent_yen: '',
        rent1: '',
        rent1_yen: '',
        rent2: '',
        rent2_yen: ''
      }
      this.page_dialog_visible = true
    },
    editAction(item) {
      this.pageForm = {
        id: item.id,
        developersId: item.developersId,
        distributorId: item.distributorId,
        roofType:item.roofType,
        type: item.type,
        type1: item.type1,
        type2: item.type2,
        startDate: item.startDate,
        endDate: item.endDate,
        _date: [item.startDate, item.endDate],
        periods: item.periods,
        rent: item.rent,
        rent_yen: item.rent ? item.rent / 100 : '',
        rent1: item.rent1,
        rent1_yen: item.rent1 ? item.rent1 / 100 : '',
        rent2: item.rent2,
        rent2_yen: item.rent2 ? item.rent2 / 100 : '',
        utype: item.utype,
        version: item.version
      }
      this.page_dialog_visible = true
    },
    typeChange(val) {
      let type = this.type_options.find(item => {
        return item.value == val
      })
      if (type) {
        this.pageForm.type1 = type.type1
        this.pageForm.type2 = type.type2
      }
    },
    dateChange(val) {
      if (val) {
        this.pageForm.startDate = val[0]
        this.pageForm.endDate = val[1]
      } else {
        this.pageForm.startDate = ''
        this.pageForm.endDate = ''
      }
    },
    yenChange(val, key) {
      this.pageForm[key] = parseFloat(val) * 100
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        if(that.pageForm.developersId===''&&that.pageForm.distributorId===''){
          that.$message.error("请选择一个开发商或者经销商！")
          return
        }
        if(that.pageForm.distributorId!==''&&that.pageForm.distributorId!==null){
          that.pageForm.utype=1
        }else {
          that.pageForm.utype=0
        }
        that.page_form_loading = true
        let func
        // eslint-disable-next-line no-prototype-builtins
        if (that.pageForm.hasOwnProperty('id')) {
          func = configureUpdate
        } else {
          func = configureCreate
        }
        func(that.pageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.page_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    infoAction(item) {
      if(item.utype==0){
        this.$router.push({path: '/admin/rent-set-developer-info/' + item.id})
      }else {
        this.$router.push({path: '/admin/rent-set-distributor-info/' + item.id})
      }
    },
    changeStateAction(item) {
      let that = this
      that.$confirm('此操作将更改状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        configureChangeState(item.id, item.state ? 0 : 1).then(() => {
          that.$message.success('操作成功');
          this.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    exportData(){
      let that = this,ids = []
      let selected = that.$refs.table.selection
      for (let item of selected){
        ids.push(item.id)
      }
      let data = Object.assign(that.searchModel, {
        exportIds: ids.length ? ids.join(',') : ''
      })
      configureExport(data).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
